import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    Button
} from '@material-ui/core';
import {
    getServices,
    addToCart,
    removeFromCart,
    getEmployees,
    selectEmployee,
    selectDate,
    createBooking,
    reInitBooking,
    cancelBooking,
    updateBooking,
    changeBooking,
    updateCustomer,
    setConsentId,
} from '../actions';
import api from '../api';
import BookingHeader from '../components/BookingHeader';
import BookingStep1 from '../components/BookingStep1';
import BookingStep2 from '../components/BookingStep2';
import BookingStep3 from '../components/BookingStep3';
import BookingStep4 from '../components/BookingStep4';
import BookingStep5 from '../components/BookingStep5';

const renderStep = (param, step) => {
    switch (step) {
        case 1:
            return <BookingStep1
                center={param.center}
                cart={param.cart}
                updateCustomer={param.handleUpdateCustomer}
                handleNext={param.handleNext}
                selfCheckin={param.selfCheckin}
                setisExisting={param.setisExisting}
            ></BookingStep1>

        case 2:
            return <BookingStep2
                loading={param.loading}
                center={param.center}
                services={param.services}
                cart={param.cart}
                handleCart={param.handleCart}
                handleBack={param.handleBack}
                setCovidConsentId={param.setCovidConsentId}
                selfCheckin={param.selfCheckin}
                handleNext={param.handleNext}
            ></BookingStep2>

        case 3:
            return <BookingStep3
                loading={param.loading}
                center={param.center}
                cart={param.cart}
                note={param.note}
                employees={param.employees}
                handleSelectEmployee={param.handleSelectEmployee}
                handleSetNote={param.handleSetNote}
            ></BookingStep3>

        case 4:
            return <BookingStep4
                center={param.center}
                cart={param.cart}
                updateCustomer={param.handleUpdateCustomer}
                handleNext={param.handleNext}
                handleBooking={param.handleBooking}
            ></BookingStep4>

        case 5:
            return <BookingStep5
                loading={param.loading}
                center={param.center}
                services={param.services}
                cart={param.cart}
                booking={param.booking}
                handleChangeBooking={param.handleChangeBooking}
                center={param.center}
                cart={param.cart}
                booking={param.booking}
                newBooking={param.newBooking}
                handleCancel={param.handleCancel}
                handleEdit={param.handleEdit}
                handleChangeBooking={param.handleChangeBooking}
            ></BookingStep5>

        default:
            break;
    }
}

const BookingModal = (props) => {
    const {center = {}, booking = {}} = props || {};
    const [step, setStep] = useState(props.isManageBooking ? 4 : 1);
    const [isManageBooking, setIsManageBooking] = useState(props.isManageBooking);
    const [note, setNote] = useState("");
    const [isExisting, setisExisting] = useState(true)

    // useEffect(() => {
    //     if (step === 1) props.dispatch(getServices(center.id));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        if (props.booking.booking_id) {
            handleNext(5);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.booking.count]);

    useEffect(() => {
        if (booking.booking_id) {
            getEmployeesByService();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [booking.booking_id]);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let time = null;
    if (props.cart.date) {
        const h = props.cart.date.getHours() > 12 ? props.cart.date.getHours() - 12 : props.cart.date.getHours();
        const m = props.cart.date.getMinutes();
        let period = (props.cart.date.getHours() >= 12) ? 'pm' : 'am';
        time = `${h}:${('0' + m).slice(-2)} ${period}`;
    }

    const handleSelectTimeSlot = date => {
        props.dispatch(selectDate(date));
    };

    const handleSelectEmployee = selectedEmployee => {
        props.dispatch(selectEmployee(selectedEmployee));
    };

    const getEmployeesByService = () => {
        const serviceArr = props.cart.item.map(i => i.id);
        const d = new Date();
        const utc_offset = (d.getTimezoneOffset() * -1);
        props.dispatch(getEmployees(serviceArr, props.center.id, utc_offset));
    };

    const handleCart = (action, sid) => {
        if (action === 'add') {
            if (props.cart.item.length < 6) {
                const toAdd = props.services.map(cat => {
                    return cat.services.filter(service => service.id === sid);
                }).filter(i => i.length)[0];
                toAdd.length && props.dispatch(addToCart({item: toAdd[0], is_tax_online: center.is_tax_online}));
            }
        } else {
            props.dispatch(removeFromCart({sid, is_tax_online: center.is_tax_online}));
        }
    }

    const handleNext = (currentStep = step,data) => {
        let nextStep = currentStep;
        console.log("in handle next", currentStep)
        if(currentStep!=="skip"){
            if( currentStep!==5){
                console.log("hllo")
                if (props?.center?.is_services_display == false) {
                    console.log("display false")
                    if (isExisting) {
                        console.log("user exist",props?.center)
                        if (props?.center?.covid_consent_form == false) {
                            console.log("covid false")
                            nextStep = 5
                            currentStep = 5
                        } else {
                            console.log("covid true")
                            nextStep = 2
                            currentStep = 2
                        }
                    } else {
                        console.log("user not exist")
                        if (props?.center?.covid_consent_form == false) {
                            console.log("covid false")
                            nextStep = 5
                            currentStep = 5
                            handleNext("skip")
                        } else {
                            console.log("covid true")
                            nextStep = 2
                            currentStep = 2
                        }
                    }
                }else{
                    if (props?.center?.is_employee == false) {
                        if(currentStep==2){
                            nextStep = 5
                            currentStep = 3
                        }
                    }
                }
            }
        }else{
            console.log("book",props)
            nextStep = 4
            currentStep = 4
        }

        switch (currentStep) {
            case 1:
                props.dispatch(getServices(center.id));
                nextStep = 2;
                break;
            case 2:
                if (props.cart.item.length) {
                    if (props.center.is_employee) {
                        getEmployeesByService();
                        nextStep = 3;
                    } else {
                        handleSelectEmployee({
                            id: 0,
                            username: 'anyone',
                            job_title: 'Maximum Availability'
                        });
                        nextStep = 3;
                    }
                }
                break;

            case 3:
                // if (props.cart.employee.hasOwnProperty('id') && props.employees.length) {
                //     nextStep = 4;
                // }
                handleBooking()
                break;

            case 4:
                if (props.cart.customer.id) {
                    console.log("doing book")
                    nextStep = 5;
                    handleBooking(data)
                }
                break;

            default:
                break;
        }
        setStep(nextStep);
    }

    const handleBack = () => {
        let previousStep = step;
        switch (step) {
            case 2:
                previousStep = 1;
                break;

            case 3:
                if (props.center.is_employee) {
                    previousStep = 2;
                } else {
                    previousStep = 1;
                }
                break;

            case 4:
                previousStep = 3;
                break;

            default:
                break;
        }
        setStep(previousStep);
    };

    // const handleBooking = (customer_id, customer_name, note) => {
    //     const { center, cart } = props;
    //     const { employee } = cart;
    //     const d = new Date();
    //     const utc_offset = (d.getTimezoneOffset() * -1);
    //     const data = {
    //         spa_center_id: center.id,
    //         customer_id,
    //         customer_name,
    //         date: `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`,
    //         time: `${date.getHours()}:${date.getMinutes()}`,
    //         employee_id: employee.id,
    //         note,
    //         utc_offset
    //     };
    //     data.service_id = cart.item.map(i => +i.id).join();
    //     props.dispatch(createBooking(data));
    // };

    const handleBooking = (cid) => {
        const {center, cart} = props;
        const {employee, customer, consentId} = cart;
        console.log("cartmy",cart)
        const d = new Date();
        const utc_offset = (d.getTimezoneOffset() * -1);
        if (!customer.id) {
            return
        }
        props.dispatch(setConsentId(null));
        const data = {
            spa_center_id: center.id,
            customer_id: customer.id || "",
            customer_name: customer.fillname,
            employee_id: employee.id || 0,
            note: note,
            utc_offset,
            covid_form_id: consentId || cid || ""
        };
        data.service_id = cart.item.map(i => +i.id).join() || "";
        props.dispatch(createBooking(data));

        setStep(4);
    };

    const newBooking = () => {
        props.dispatch(getServices(center.id));
        props.dispatch(reInitBooking());
        setStep(1);
    };

    const onClose = () => {
        setStep(1);
        props.onClose();
    }

    const handleEdit = () => {
        if (booking.booking_id) {
            handleNext(2);
        }
    }

    const handleCancel = () => {
        const {booking_id = null} = booking;
        const d = new Date();
        const utc_offset = (d.getTimezoneOffset() * -1);
        if (booking.booking_id) {
            props.dispatch(cancelBooking({booking_id, utc_offset}));
            newBooking();
        }
    };

    const handleUpdateBooking = () => {
        const {booking, cart} = props;
        const {date} = cart;
        const {booking_id} = booking;
        const d = new Date();
        const utc_offset = (d.getTimezoneOffset() * -1);
        const data = {
            date: `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`,
            time: `${date.getHours()}:${date.getMinutes()}`,
            utc_offset
        };
        props.dispatch(updateBooking({data, booking_id}));
    };

    const handleChangeBooking = detail => {
        props.dispatch(changeBooking(detail));
    };

    const handleUpdateCustomer = (payload) => {
        props.dispatch(updateCustomer(payload));
    };

    const showBookNow = () => {
        switch (step) {
            case 1:
                return props.cart.item.length;

            case 2:
                return props.cart.item.length;

            case 3:
                // return props.cart.employee.hasOwnProperty('id') && props.employees.length;
                return true;

            case 4:
                return props.cart.date;

            default:
                return false;
        }
    }

    const handleSetNote = (event) => {
        setNote(event.target.value)
    }

    const setCovidConsentId = (consentId) => {
        props.dispatch(setConsentId(consentId));
    }

    const selfCheckin = (mobileNo) => {
        const d = new Date();
        const utc_offset = (d.getTimezoneOffset() * -1);

        return api.post('/customer/selfcheckin', {
            mobile: mobileNo,
            center_id: props.center.id,
            utc_offset: utc_offset
        }).then(res => {
            console.log("RESPONSE SELF CHECKIN", res)
        }).catch((error) => {
            console.log("ERROR SELF CHECKIN", error);
        });
    }

    return (
        <Dialog
            open={props.show}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="main-popup"
            maxWidth="lg"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            fullScreen
        >
            <BookingHeader step={step} handleBack={handleBack} center={props.center}
                           consentId={props.cart.consentId}></BookingHeader>
            <DialogContent>
                {
                    renderStep({
                        loading: props.loading,
                        center: props.center || {},
                        services: props.services || [],
                        cart: props.cart || {},
                        employees: props.employees || [],
                        booking: props.booking || {},
                        handleCart,
                        handleSelectEmployee,
                        handleNext,
                        handleBack,
                        handleSelectTimeSlot,
                        handleBooking,
                        newBooking,
                        handleCancel,
                        handleEdit,
                        handleChangeBooking,
                        isManageBooking,
                        handleUpdateCustomer,
                        note,
                        handleSetNote,
                        setCovidConsentId,
                        selfCheckin,
                        isExisting,
                        setisExisting
                    }, step)
                }
            </DialogContent>
            <DialogActions>
                <Grid item xs={7} sm={7} md={7}>
                    {!!(props.cart.item.length && step !== 5) && <div className="mob-data">
                        <span className="slct-srvc">{props.cart.item.length} services selected </span>
                        {props.center.is_service_price && <span className="amt">${props.cart.total} </span>}
                        <div className="mob-data-btm">
                            <span>
                                {(props.cart.employee && props.cart.employee.username) &&
                                <React.Fragment>with <strong>{props.cart.employee.username.toUpperCase()}</strong></React.Fragment>}
                                {!!props.cart.date &&
                                <React.Fragment> on <strong> {`${props.cart.date.getDate()} ${months[props.cart.date.getMonth()]}`},</strong>
                                    <strong>{time}</strong> </React.Fragment>}
                            </span>

                        </div>
                    </div>}
                </Grid>
                <Grid item xs={5} sm={5} md={5} className="btn-book-now-wrapp">
                    {!!showBookNow() && <Button variant="contained" className="btn-blue btn-booknow" onClick={e => {
                        e.preventDefault();
                        handleNext();
                    }}> BOOK NOW </Button>}
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = dispatch => ({dispatch});
const mapStateToProps = state => ({
    center: state.center || {},
    services: state.services || [],
    cart: state.cart || {},
    employees: state.employees || [],
    booking: state.booking || {},
    loading: state.app.loading
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
