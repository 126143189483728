import requestService from '../services/requestService';

const getEmployees = query => {
    const request = {
        method: 'get',
        url: `/center/${query.cid}/employee`,
        params: {
            service: query.service.join(','),
            utc_offset: query.utc_offset,
            type: 'get_employees',
        }
    };

    return requestService(request);
};

const getEmployeeAvailability = query => {
    const request = {
        method: 'get',
        url: `/employee/${query.eid}/availability`,
        params: {
            date: query.date,
            sid: query.sid.join(','),
            cid: query.cid,
            type: 'get_employee_availability',
        }
    };

    return requestService(request);
};

export default {
    getEmployees,
    getEmployeeAvailability
};
