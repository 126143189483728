import requestService from '../services/requestService';

const getServices = query => {
  const request = {
    method: 'get',
    url: `/center/${query.cid}/category/service`,
    params: {
      type: 'get_services',
    }
  };

  return requestService(request);
};

export default {
  getServices
};
