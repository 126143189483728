import requestService from '../services/requestService';

const createBooking = (data, query) => {
  const request = {
    method: 'post',
    url: `/booking`,
    params: {
      type: 'create_booking',
    },
    data
  };

  return requestService(request);
};

const cancelBooking = (data, query) => {
  const request = {
    method: 'post',
    url: `/booking/${data.booking_id}/cancel`,
    params: {
      type: 'cancel_booking',
    },
    data
  };

  return requestService(request);
};

const updateBooking = (data, query) => {
  const request = {
    method: 'post',
    url: `/booking/${query.booking_id}`,
    params: {
      type: 'update_booking',
    },
    data
  };

  return requestService(request);
};

const checkForEdit = query => {
  const request = {
    method: 'get',
    url: `/booking/${query.bid}/edit/check`,
    params: {
      type: 'check_for_edit',
      utc_offset: query.utc_offset
    }
  };

  return requestService(request);
};

const getCustomerBooking = query => {
  const request = {
    method: 'get',
    url: `/booking/center/${query.center_id}/mobile/${query.mobile}`,
    params: {
      type: 'get_customer_booking',
      utc_offset: query.utc_offset
    }
  };
  return requestService(request);
};

const updateBookingNote = (data) => {
  const request = {
    method: 'post',
    url: `/booking/${data.id}/note`,
    params: {
      type: 'update_note',
    },
    data
  };

  return requestService(request);
};

const submitCovidConsent = (data) => {
  const request = {
    method: 'post',
    url: `/covid/consent`,
    params: {
      type: 'covid_consent_form',
    },
    data
  };

  return requestService(request);
}

export default {
  createBooking,
  cancelBooking,
  updateBooking,
  checkForEdit,
  getCustomerBooking,
  updateBookingNote,
  submitCovidConsent
};
