import React, { useState, useEffect, createRef } from 'react';
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
	Grid,
	RadioGroup,
	Checkbox,
	FormControlLabel,
	TextField
} from '@material-ui/core';
import Slider from "react-slick";

import Cart from './Cart';
import Loader from './Loader';
import duration from '../helpers/duration';
import CovidForm from './CovidForm';
import api from '../api';

const useStyles = makeStyles({
	icon: {
		borderRadius: "50%",
		width: 16,
		height: 16,
		boxShadow:
			"inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
		backgroundColor: "#f5f8fa",
		backgroundImage:
			"linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5"
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)"
		}
	},
	checkedIcon: {
		backgroundColor: "#14B9DB",
		backgroundImage:
			"linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
			content: '""'
		},
		"input:hover ~ &": {
			backgroundColor: "#106ba3"
		}
	}
});

const settings = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow:6,
	slidesToScroll: 4,
	autoplay: false, 
	className: 'category-slider',
	variableWidth: true,
	responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 5,
            slidesToScroll:1, 
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow:3,
            slidesToScroll:1, 
			// variableWidth: true,
			focusOnSelect: true,
			arrows:false,
			// infinite: false
          }
        } 
	]
};

const BookingStep2 = React.memo(props => {
	var isShowForm = false;
	if(props.center.covid_consent_form && props.cart.consentId === null)
		isShowForm = true;

	const [covidVerified, setCovidVerfied] = useState(isShowForm);
	const classes = useStyles();
	const [selectedCategory, setSelectedCategory] = useState({
		services: []
	});
	const [allCategories, setAllCategories] = useState([{
		id: 0,
		name: 'All Services',
		services: []
	}]);
	const [selectedServces, setSelectedServices] = useState([]);

	const searchRef = createRef();

	useEffect(() => {
		if (props.services.length) {
			let allServices = [];
			const allCategoriesNew = allCategories;
			props.services.forEach(i => {
				allCategoriesNew.push(i);
				allServices = [...allServices, ...i.services];
			});
			allCategoriesNew[0].services = allServices;
			setSelectedCategory({ ...allCategoriesNew[0] });
			setAllCategories(allCategoriesNew);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.services]);

	useEffect(() => {
		setSelectedServices(props.cart.item.map(i => i.id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.cart.item.length]);

	const onCatClick = (e, id) => {
		e.preventDefault();
		const val = searchRef.current.value;
		const category = allCategories.filter(i => i.id === id)[0] || { ...allCategories[0] };
		setSelectedCategory({
			...category,
			services: val ? category.services.filter(i => i.name.toLowerCase().includes(val)) : category.services
		});
	}

	const handleChange = (e, id) => {
		props.handleCart(e.target.checked ? 'add' : 'remove', id);
	}

	const onSearch = () => {
		const val = searchRef.current.value;
		const { services: allServices = [] } = allCategories.filter(i => i.id === selectedCategory.id)[0] || {};
		setSelectedCategory({
			id: selectedCategory.id,
			name: selectedCategory.name,
			services: val ? allServices.filter(i => i.name.toLowerCase().includes(val)) : allServices
		});
	}

	const moveBack = () => {
		setCovidVerfied(false);
		props.handleBack(2);
	}

	const setUnsetCovidVerfied = (mobile, centerId) => {
		//setCovidVerfied(false);
		const d = new Date();
		const utc_offset = (d.getTimezoneOffset() * -1);
		getBooking(mobile, centerId, utc_offset).then(async res => {			
			const { data } = res;			
			if (data.bookings.length) {
				await props.selfCheckin(mobile);
				setCovidVerfied(false);
				props.handleNext(5);
			}else{
				setCovidVerfied(false);
			}
		}).catch((error) => {
			console.log("ERROR COVID FORM BOOK2", error);
		});
	}

	const getBooking = (mobile, center_id, utc_offset) => {
		return api.get('/booking/center/:center_id/mobile/:mobile', { params: { mobile, center_id, utc_offset } });
	};
	

	return (		
		<div>
			{covidVerified && 
				<CovidForm 
					customer={props.cart.customer} 
					centerDetails={props.center} 
					moveBack={() => moveBack()}
					setUnsetCovidVerfied={setUnsetCovidVerfied}
					setCovidConsentId={props.setCovidConsentId}
					selfCheckin = {props.selfCheckin}
					handleNext={props.handleNext}
				/>
			}
			{!covidVerified && <>
			<Slider {...settings}>
				{
					allCategories.map(i => <a key={i.id} href="/#" className={i.id === selectedCategory.id ? 'active' : ''} onClick={e => onCatClick(e, i.id)}>{i.name}</a>)
				}
			</Slider>			
			<Grid container spacing={8} className="popup-container">
				<Grid item xs={12} sm={12} md={7} lg={7}>
					<div className="search-box">
						<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M27.0703 25.4898L20.5516 18.9711C22.143 17.0297 23.0945 14.5523 23.0945 11.8453C23.0945 5.63828 18.0578 0.601562 11.8508 0.601562C5.63828 0.601562 0.601562 5.63828 0.601562 11.8508C0.601562 18.0633 5.63828 23.1 11.8508 23.1C14.5523 23.1 17.0352 22.1484 18.9766 20.557L25.4898 27.0703C25.9273 27.5078 26.6328 27.5078 27.0703 27.0703C27.5078 26.6328 27.5078 25.9273 27.0703 25.4898ZM2.83281 11.8508C2.83281 6.87422 6.86875 2.83828 11.8453 2.83828C16.8219 2.83828 20.8578 6.87422 20.8578 11.8508C20.8578 16.8273 16.8219 20.8633 11.8453 20.8633C6.86875 20.8633 2.83281 16.8273 2.83281 11.8508Z" fill="#082B44" fillOpacity="0.3"/>
						</svg>
 						<TextField id="outlined-basic" label="Search by a service name" variant="outlined" onChange={onSearch} inputRef={searchRef} />
					</div>
					<div className="list-of-radio-wrapp">
						<h3 title={selectedCategory.name}>{selectedCategory.name}</h3>
						<RadioGroup aria-label="gender" name="customized-radios">
							<ul className="list-of-radio">
								{
									props.loading ? <Loader /> : (
										<>
											{
												selectedCategory.services.map(service =>
													<li key={service.id}>
														<FormControlLabel
															value={service.id}
															control={
																<Checkbox
																	name={service.name}
																	onChange={e => handleChange(e, service.id)}
																	checked={selectedServces.includes(service.id)}
																	color="primary"
																	icon={<span className={classes.icon} />}
																	checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
																/>
															}
															label={service.name}
														/>
														{!! props.center.is_service_price && <div className="amt">$ {service.price}</div>}
														{!! props.center.is_duration && <span className="time">{duration(service.duration)}</span>}
														{service.description && <p>{service.description}</p>}
													</li>
												)
											}
										</>
									)
								}
							</ul>
						</RadioGroup>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={5} lg={5} className="hide-in-mob">
					<Cart
						center={props.center}
						cart={props.cart}
					></Cart>
				</Grid>
			</Grid>
			</>}
		</div>
	);
});

export default BookingStep2;