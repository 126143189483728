import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCenterByKey, getOwnerOtherCenters, closeBooking, getOwnerCenters } from '../actions';

import Header from '../components/Header';
import SpaInfo from '../components/SpaInfo';
import About from '../components/About';
import OtherLocation from '../components/OtherLocation';
import Footer from '../components/Footer';

import BookingModal from './BookingModal';

const Owner = ( props ) => {
    const { params } = props.match || {}
    useEffect(() => {
        props.dispatch(getOwnerCenters(params.key));
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.centers.length) {
            props.dispatch(getCenterByKey(props.centers[0].store_key));
        }
    }, [props.centers.length]);

    const {
        id,
        website_link = '',
        center_name = '',
        address = '',
        tel = '',
        about_us = '',
        slider_images_list = [],
        rating = 0,
        rating_count = 0
    } = props.center || {};

    const [bookNow, setBookNow] = useState(false);
    const [isManageBooking, setIsManageBooking] = useState(1);
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

    const updateWindowDimensions = () => {
        setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    const onClose = () => {
        props.dispatch(closeBooking());
        setIsManageBooking(false);
        setBookNow(false);
    };

    const manageBooking = () => {
        setIsManageBooking(true);
        setBookNow(true);
    }

    const launchBooking = () => {
        setIsManageBooking(false);
        setBookNow(true);
    }

    return (
        <div className="main-wrapper">
            <Header
                website_link={website_link}
                bookNow={launchBooking}
                manageBooking={manageBooking}
                tel={tel}
            ></Header>
            <SpaInfo
                center_name={center_name}
                address={address}
                rating={rating}
                rating_count={rating_count}
                bookNow={launchBooking}
                slider_images_list={slider_images_list || []}
            ></SpaInfo>
            <About
                id={id}
                bookNow={launchBooking}
                about_us={about_us}
                rating={rating}
                rating_count={rating_count}
                address={address}
            ></About>
            {!!props.owner_other_centers.length && <OtherLocation locations={props.owner_other_centers}></OtherLocation>}
            <Footer bookNow={launchBooking}></Footer>
            {!!bookNow && <BookingModal
                show={bookNow}
                onClose={onClose}
                isManageBooking={isManageBooking}
                height={dimensions.height}
                width={dimensions.width}
            ></BookingModal>}
        </div>
    );
}

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = state => ({
    centers: state.owner.centers || [],
    center: state.center || {},
    loading: state.app.loading,
    owner_other_centers: (state.owner && state.owner.centers && state.owner.centers.filter(i => i.store_key !== state.center.store_key)) || []
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Owner));
