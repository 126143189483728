import React, {useEffect, useState} from 'react';
import {
    TextField,
    Button,
    Grid
} from '@material-ui/core';
import Cart from './Cart';
import api from '../api';
import CovidForm from "./CovidForm";

const renderStep = (param, currentStep, {checkbox, isCheck}) => {
    const {fields, setisExisting} = param;
    const dt = new Date();
    dt.setYear(dt.getFullYear() - 18);
    const maxDate = `${dt.getFullYear()}-${('0' + (dt.getMonth() + 1)).slice(-2)}-${('0' + dt.getDate()).slice(-2)}`;
    switch (currentStep) {
        case 1:
            return <>
                <div className="form-box enter-phone-no">
                    <h5>Please insert your phone number to {param.isManageBooking ? 'find' : 'complete'} check-in.</h5>
                    <div className="form-box-inside">
                        <TextField placeholder="Phone Number" maxLength="10" value={fields.mobile.value}
                                   variant="outlined" onChange={e => param.handleChange(e, 'mobile')}/>
                        {!fields.mobile.isValid && <small className="err">Invalid Mobile</small>}
                        {param.noAppointment && <small
                            className="err">{`There is no upcoming appointment assigned with phone number ${fields.mobile.value}`}</small>}
                        <Button disabled={!isCheck} className="btn-blue" variant="contained" onClick={e => {
                            e.preventDefault();
                            param.sendActivationCode();
                        }}>
                            {
                                param.loading ? <><span
                                    className="spinner-grow spinner-grow-sm"></span>Loading...</> : 'Check in'
                            }
                        </Button>
                    </div>
                    <label form="agree">
                        <p className="form-note">{checkbox} By providing your phone number you hereby agree to sign up
                            for an account with our salon. You may be asked for additional information regarding your
                            account, such as your name, e-mail address and other information related to your profile. We
                            may also use your phone number to send you service-related notices such as special offers,
                            feedback, notification.</p>
                    </label>
                </div>
            </>

        case 2:
            return <>
                <div className="form-box insert-activate-code">
                    <h6>Please defrinsert activation code sent to you by SMS to complete check-in.</h6>
                    <div className="form-box-inside">
                        <TextField maxLength="6" value={fields.activation_code.value} variant="outlined"
                                   name="activation_code" placeholder="Enter Activation Code"
                                   onChange={e => param.handleChange(e, 'activation_code')}/>
                        {!fields.activation_code.isValid && <small className="err">Invalid Code</small>}
                        <Button className="btn-blue" variant="contained" onClick={e => {
                            e.preventDefault();
                            param.activateMobile();
                            setisExisting(false)
                        }}>
                            {
                                param.loading ? <><span
                                    className="spinner-grow spinner-grow-sm"></span>Loading...</> : 'SUBMIT CODE'
                            }
                        </Button>
                        {!!param.activationCodeCount <= 3 && <p className="form-txt-ctrl">Didn't get Code? <a href="/#"
                                                                                                              className="btn-link float-right color-blue"
                                                                                                              onClick={e => {
                                                                                                                  e.preventDefault();
                                                                                                                  param.sendActivationCode();
                                                                                                              }}>Resend
                            SMS</a></p>}
                    </div>
                </div>
            </>

        case 3:
            return <>
                <div className="form-box personal-details-form">
                    <h5>Customer Registration.</h5>
                    <div className="form-box-inside">
                        <form autoComplete="off">
                            <label className="fm-ttl">Full name {!fields.name.isValid &&
                            <small className="err">This field is required</small>}</label>
                            <TextField variant="outlined" name="name" value={fields.name.value || ''}
                                       placeholder="Enter your name" onChange={e => param.handleChange(e, 'name')}
                                       disabled={!!fields.customer_id}/>

                            {!fields.customer_id && (<>
                                <label className="fm-ttl">Email <small>(optional)</small> {!fields.email.isValid &&
                                <small className="err">Please enter a valid email address</small>}</label>
                                <TextField variant="outlined" name="email" value={fields.email.value || ''}
                                           placeholder="Enter your email"
                                           onChange={e => param.handleChange(e, 'email')}/>
                            </>)}

                            <label className="fm-ttl">Phone Number {!fields.mobile.isValid &&
                            <small className="err">Please enter a valid Mobile no.</small>}</label>
                            <TextField variant="outlined" name="mobile" value={fields.mobile.value || ''}
                                       placeholder="Enter your phone number"
                                       onChange={e => param.handleChange(e, 'mobile')} disabled={true}/>

                            {!fields.customer_id && (<>
                                <label className="fm-ttl">Your
                                    Birthday <small>(optional)</small> {!fields.birthday.isValid &&
                                    <small className="err">Please enter a valid Birthday</small>}</label>
                                <TextField type="date" variant="outlined" name="birthday"
                                           value={fields.birthday.value || ''} placeholder="Enter your birthday"
                                           onChange={e => param.handleChange(e, 'birthday')}
                                           inputProps={{max: maxDate}}/>
                            </>)}

                            {!fields.customer_id && (<>
                                <label className="fm-ttl">Referal
                                    Code <small>(optional)</small> {!fields.referral_code.isValid &&
                                    <small className="err">Invalid referral code</small>}</label>
                                <TextField variant="outlined" name="referral_code"
                                           value={fields.referral_code.value || ''} placeholder="Enter referral code"
                                           onChange={e => param.handleChange(e, 'referral_code')}/>
                            </>)}

                            {/* <label className="fm-ttl">Booking Notes <small>(optional)</small></label>
                      	<TextField variant="outlined" multiline rows={4} name="notes" value={fields.notes.value || ''} placeholder="Enter notes for booking" onChange={e => param.handleChange(e, 'notes')} /> */}

                            <Button className="btn-blue" variant="contained" onClick={e => {
                                e.preventDefault();
                                param.completeBooking();
                            }}>
                                {
                                    param.loading ? <><span
                                        className="spinner-grow spinner-grow-sm"></span>Loading...</> : 'Sign up'
                                }
                            </Button>
                        </form>
                    </div>
                </div>
            </>
        default:
            break;
    }
    ;
};

const getCustomer = (mobile, center_id, utc_offset) => {
    return api.get('/customer/mobile/:mobile', {params: {mobile, center_id, utc_offset}});
};

const getBooking = (mobile, center_id, utc_offset) => {
    console.log("jhfg")
    return api.get('/booking/center/:center_id/mobile/:mobile', {params: {mobile, center_id, utc_offset}});
};

const addCustomer = data => {
    return api.post('/customer', data);
};

const BookingStep1 = React.memo(props => {
    const formFields = {
        customer_id: null,
        name: {
            isValid: true,
            value: ''
        },
        mobile: {
            isValid: true,
            value: ''
        },
        notes: {
            isValid: true,
            value: ''
        },
        activation_code: {
            isValid: true,
            value: ''
        },
        email: {
            isValid: true,
            value: ''
        },
        birthday: {
            isValid: true,
            value: ''
        },
        referral_code: {
            isValid: true,
            value: ''
        }
    };
    const [step, setStep] = useState(1);
    const [token, setToken] = useState(null);
    const [fields, setFields] = useState(formFields);
    const [noAppointment, setNoAppointment] = useState(false);
    const [loading, setLoading] = useState(!props.center.id);
    const [activationCodeCount, setActivationCodeCount] = useState(0);
    const [isCheck, setCheck] = useState(true)
    useEffect(() => {
        setLoading(!props.center.id);
    }, [props.center.id]);

    // useEffect(() => {
    // 	if (props.cart.customer.id) {
    // 		props.handleNext(4);
    // 	}
    // }, [props.cart.customer.id]);

    const handleChange = (e, fieldName) => {
        const stateFields = fields;
        const maxLength = 10;
        const value = fieldName === 'mobile' ? e.target.value.replace(/[^0-9]/g, '') : e.target.value;
        if (stateFields.hasOwnProperty(fieldName)) {
            if (fieldName === 'mobile') {
                setNoAppointment(false);
                stateFields[fieldName].value = (value.length > maxLength ? value.slice(0, maxLength) : value);
            } else {
                stateFields[fieldName].value = value;
            }
        }
        setFields({...stateFields});
    };

    const validateFields = fieldsToValidate => {
        const stateFields = fields;
        let count = fieldsToValidate.length;
        for (let key in stateFields) {
            if (stateFields.hasOwnProperty(key) && fieldsToValidate.includes(key)) {
                if (key !== 'customer_id') stateFields[key].isValid = false;
                switch (key) {
                    case 'name':
                        if (stateFields[key].value) {
                            stateFields[key].isValid = true;
                            count--;
                        }
                        break;

                    case 'mobile':
                        const regex = RegExp(/^[1-9]\d{9}$/);
                        stateFields[key].isValid = regex.test(stateFields[key].value);
                        if (stateFields[key].isValid) {
                            count--;
                        }
                        break;

                    case 'notes':
                        stateFields[key].isValid = true;
                        count--;
                        break;

                    case 'activation_code':
                        if (stateFields[key].value) {
                            stateFields[key].isValid = true;
                            count--;
                        }
                        break;

                    case 'email':
                        stateFields[key].isValid = (!stateFields[key].value || /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(stateFields[key].value));
                        if (stateFields[key].isValid) {
                            count--;
                        }
                        break;

                    case 'birthday':
                        stateFields[key].isValid = true;
                        count--;
                        break;

                    case 'referral_code':
                        stateFields[key].isValid = true;
                        count--;
                        break;

                    default:
                        break;
                }
            }
        }
        setFields({...stateFields});
        return count <= 0;
    };

    const activateMobile = () => {
        setLoading(true);
        return api.post('/customer/verify-otp', {
            mobile: fields.mobile.value,
            cid: props.center.id,
            token,
            otp: fields.activation_code.value
        }).then(res => {
            try {
                setLoading(false);
                const center_id = props.center.id;
                let activatedMob = JSON.parse(localStorage.getItem('activated_mob') || '{}');
                if (Array.isArray(activatedMob)) {
                    activatedMob = {}
                }
                const centerActivatedMobiles = activatedMob[center_id] || [];
                if (!centerActivatedMobiles.includes(fields.mobile.value)) {
                    centerActivatedMobiles.push(fields.mobile.value);
                    activatedMob[center_id] = centerActivatedMobiles;
                    localStorage.setItem('activated_mob', JSON.stringify(activatedMob));
                }
                afterActivation();
            } catch (e) {
                setLoading(false);
                setFields({...fields, activation_code: {...fields.activation_code, isValid: false}});
            }
        }).catch(e => {
            setLoading(false);
            setFields({...fields, activation_code: {...fields.activation_code, isValid: false}});
        });
    };

    const afterActivation = async () => {
        // const stateFields = fields;
        // getCustomer(fields.mobile.value, props.center.id).then(res => {
        // 	const { data } = res;
        // 	const dt = new Date(data.birthday);
        // 	stateFields.name.value = data.fullname;
        // 	stateFields.email.value = data.email;
        // 	stateFields.birthday.value = data.birthday ? `${dt.getFullYear()}-${('0' + (dt.getMonth() + 1)).slice(-2)}-${('0' + dt.getDate()).slice(-2)}` : '';
        // 	stateFields.customer_id = data.id;
        // 	setFields(stateFields);
        // 	if (props.isManageBooking) {
        // 		props.updateCustomer(data.id, data.fullname);
        // 		props.handleNext(4);
        // 	}
        // 	else setStep(3);
        // }).catch(() => {
        // 	setStep(3);
        // });
        setLoading(true);
        const d = new Date();
        const utc_offset = (d.getTimezoneOffset() * -1);
        getBooking(fields.mobile.value, props.center.id, utc_offset).then(async res => {
            const {data} = res;
            props.updateCustomer(data.customer);

            if (!data.bookings.length) {
                setLoading(false);
                if (props?.center?.covid_consent_form == false) {
                    console.log("in here", data)
                    if (props?.center?.is_services_display == true) {
                        props.handleNext(1);
                    }else{
                        props.handleNext("skip")
                    }

                    return
                }
                props.handleNext(1);
            } else {
                if (!props.center.covid_consent_form) {
                    await props.selfCheckin(fields.mobile.value);
                    console.log("in here", data)
                    setLoading(false);
                    if (props?.center?.covid_consent_form == false) {
                        console.log("in here", data)
                        props.handleNext("skip")
                        return
                    }
                    props.handleNext(5);
                } else {
                    setLoading(false);
                    if (props?.center?.covid_consent_form == false) {
                        console.log("in here", data)
                        props.handleNext("skip")
                        return
                    }
                    props.handleNext(2);
                }
            }
        }).catch(() => {
            setLoading(false);
            props.updateCustomer({tel: fields.mobile.value});
            setStep(3);
        });
    };

    const sendActivationCode = () => {
        const fieldsToValidate = ['mobile'];
        if (!validateFields(fieldsToValidate)) return false;
        const center_id = props.center.id;
        const activatedMob = JSON.parse(localStorage.getItem('activated_mob') || '{}');
        const centerActivatedMobiles = activatedMob[center_id] || [];
        if (centerActivatedMobiles.includes(fields.mobile.value)) {
            const stateFields = fields;
            stateFields.activation_code.value = '1234';
            setFields(stateFields);
            return afterActivation();
        }
        if (activationCodeCount > 3) return false;
        setLoading(true);
        return api.post('/customer/send-activation', {
            mobile: fields.mobile.value,
            cid: props.center.id,
            source: props.isManageBooking ? 'manage_booking' : 'new_booking'
        }).then(res => {
            const {data} = res;
            setActivationCodeCount(activationCodeCount + 1);
            setLoading(false);
            if (props.isManageBooking && (!data.count || data.count <= 0)) {
                setNoAppointment(true);
            } else {
                setToken(data.token);
                setStep(2);
            }
        });
    };

    const completeBooking = () => {
        const fieldsToValidate = ['name', 'mobile', 'notes', 'activation_code', 'email', 'birthday', 'referral_code'];
        if (!validateFields(fieldsToValidate)) return false;
        setLoading(true);
        if (fields.customer_id) {
            // props.handleBooking(fields.customer_id, fields.name.value, fields.notes.value);
        } else {
            const {
                name: {value: name},
                mobile: {value: mobile},
                email: {value: email},
                birthday: {value: birthday},
                referral_code: {value: referral_code}
            } = fields;
            const center_id = props.center.id;
            const d = new Date();
            const utc_offset = (d.getTimezoneOffset() * -1);
            addCustomer({name, mobile, email, birthday, referral_code, center_id, utc_offset}).then(res => {
                const {status, data} = res;
                props.updateCustomer({...data});
                if (props?.center?.covid_consent_form != true) {
                    if (props?.center?.is_services_display == false) {
                        props.handleNext("skip");
                    }else{
                        props.handleNext(1);
                    }
                } else {
                    props.handleNext(1);
                }
            }).catch(err => {
                const stateFields = fields;
                stateFields.referral_code.isValid = false;
                setFields(stateFields);
                setLoading(false);
            });
        }
    };

    let checkBox = <input id="agree" type="checkbox" value={isCheck} checked={isCheck} onChange={(e) => {
        setCheck(e.target.checked)
    }}/>

    return (
        <Grid container spacing={8} className="popup-container">
            <Grid item xs={12} sm={12} md={7}>
                <div className="list-of-radio-wrapp mrgn-top-30">
                    {
                        renderStep({
                            handleChange,
                            sendActivationCode,
                            activateMobile,
                            completeBooking,
                            fields,
                            noAppointment,
                            loading,
                            activationCodeCount,
                            isManageBooking: props.isManageBooking,
                            setisExisting: props.setisExisting
                        }, step, {checkbox: checkBox, isCheck})
                    }
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} className="hide-in-mob">
                <div className="mrgn-top-30">
                    <Cart
                        center={props.center}
                        cart={props.cart}
                    ></Cart>
                </div>
            </Grid>
        </Grid>
    );
});

export default BookingStep1;