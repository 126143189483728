import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCenterByKey, getOwnerOtherCenters, closeBooking } from '../actions';

import Header from '../components/Header';
import SpaInfo from '../components/SpaInfo';
import About from '../components/About';
import Footer from '../components/Footer';

import BookingModal from './BookingModal';

const Center = (props) => {
    const { params } = props.match || {};
    const {
        id,
        website_link = '',
        center_name = '',
        address = '',
        tel = '',
        about_us = '',
        slider_images_list = [],
        rating = 0,
        rating_count = 0
    } = props.center || {};

    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

    useEffect(() => {
        props.dispatch(getCenterByKey(params.key));
        if (!props.owner_other_centers.length) {
            props.dispatch(getOwnerOtherCenters(params.key));
        }
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateWindowDimensions = () => {
        setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    const onClose = () => {
        props.dispatch(closeBooking());
    };

    return (
        <div className="main-wrapper">
            <Header
                website_link={website_link}
                tel={tel}
            ></Header>
            <SpaInfo
                center_name={center_name}
                address={address}
                rating={rating}
                rating_count={rating_count}
                slider_images_list={slider_images_list || []}
            ></SpaInfo>
            <About
                id={id}
                about_us={about_us}
                rating={rating}
                rating_count={rating_count}
                address={address}
            ></About>
            <Footer></Footer>
            <BookingModal
                show
                onClose={onClose}
                height={dimensions.height}
                width={dimensions.width}
            ></BookingModal>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({ dispatch });
const mapStateToProps = state => ({
    center: state.center || {},
    loading: state.app.loading,
    owner_other_centers: (state.owner && state.owner.centers && state.owner.centers.filter(i => i.store_key !== state.center.store_key)) || []
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Center));
