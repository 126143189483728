import React from 'react';
import StarRatings from 'react-star-ratings';

import duration from '../helpers/duration';

const Cart = React.memo(props => {
    const { center, cart } = props;
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let time = null;
    if (cart.date) {
        const h = cart.date.getHours() > 12 ? cart.date.getHours() - 12 : cart.date.getHours();
        const m = cart.date.getMinutes();
        let period = (cart.date.getHours() >= 12) ? 'pm' : 'am';
        time = `${h}:${('0'+m).slice(-2)} ${period}`;
    }
    
    return (
        <div className="popup-sidebar">
            <div className="popup-sidebar-ttl-info">
                <h3 title={center.center_name}>{center.center_name}</h3>
                <span>{center.address}</span>
            </div>
            { cart.employee.hasOwnProperty('username') && <div className="popup-sidebar-time">
                    {cart.date && <label>{`${cart.date.getDate()} ${months[cart.date.getMonth()]} ${cart.date.getFullYear()}`} <strong>at</strong> {time}</label>}
                    {(cart.employee && cart.employee.username) && <p><strong>with </strong>{cart.employee.username.toUpperCase()}</p>}
                </div>
            }
            <div className="popup-sidebar-tbl">
                {
                    !cart.item.length ? (
                        <div className="no-service-msg">No services selected</div>
                    ) : (
                        <table>
                            <tbody>
                                {
                                    cart.item.map((i, index) => <tr key={index}>
                                            <td className={center.is_service_price ? '' : 'tl'}><label>{i.name.length > 20 ? (i.name.slice(0, 17) + '...') : i.name}</label> {center.is_duration && <span>{duration(i.duration)}</span>}</td>
                                            {!! center.is_service_price && <td><label>$ {i.price} </label></td>}
                                        </tr>
                                    )
                                }                                
                                { !! (cart.item.length && center.is_service_price) && (
                                    <tr className="ttl-amt">
                                        {
                                            center.is_total_online ? (
                                                <>
                                                    <td>
                                                        {center.is_tax_online && <label>Taxes</label>}
                                                        <strong>Total</strong>
                                                        {center.is_duration && <span>{duration(cart.duration)}</span>}
                                                    </td>
                                                    <td>
                                                        {center.is_tax_online && <label>$ {cart.taxAmount} </label>}
                                                        <strong>$ {cart.total} </strong>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td>
                                                        {center.is_duration && <span>{duration(cart.duration)}</span>}
                                                    </td>
                                                    <td></td>
                                                </>
                                            )
                                        }
                                    </tr>
                                )}                                
                            </tbody>
                        </table>
                    )
                }
            </div>
        </div>
    ); 
});

export default Cart;