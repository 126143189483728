import requestService from '../services/requestService';

const sendActivationCode = (data, query) => {
  const request = {
    method: 'post',
    url: `/customer/send-activation`,
    params: {
      type: 'send_activation',
    },
    data
  };

  return requestService(request);
};

const getCustomer = query => {
  const request = {
    method: 'get',
    url: `/customer/mobile/${query.mobile}`,
    params: {
      type: 'get_customer',
      center_id: query.center_id
    }
  };

  return requestService(request);
};

const addCustomer = (data, query) => {
  const request = {
    method: 'post',
    url: `/customer`,
    params: {
      type: 'add_customer',
    },
    data
  };

  return requestService(request);
};

const getUpcomingBooking = query => {
  const request = {
    method: 'get',
    url: `/customer/${query.cid}/center/${query.center_id}/booking/upcoming`,
    params: {
      type: 'get_customer_upcoming_booking',
      utc_offset: query.utc_offset,
    }
  };
  if (query.booking_id) {
    request.params.booking_id = query.booking_id;
  }
  return requestService(request);
};

const verifyOtp = (data, query) => {
  const request = {
    method: 'post',
    url: `/customer/verify-otp`,
    params: {
      type: 'verify_otp',
    },
    data
  };

  return requestService(request);
};

const selfCheckin = (data) => {
  const request = {
    method: 'post',
    url: `/customer/selfcheckin`,
    params: {
      type: 'self_checkin_covid_form',
    },
    data
  };

  return requestService(request);
}

export default {
  sendActivationCode,
  getCustomer,
  addCustomer,
  getUpcomingBooking,
  verifyOtp,
  selfCheckin
};
