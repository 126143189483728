import React from 'react';
import StarRatings from 'react-star-ratings';
import { Grid, Card, CardContent } from '@material-ui/core';

const OtherLocation = React.memo(props => {
    return (
        <section className="home-location">
            <div className="container">
                <h2>Find other locations</h2>
                <Grid container spacing={8}>
                {
                    props.locations.map(item =>                
                        <Grid item xs={12} sm={12} md={6} key={item.store_key}>
                            <div className="inside">
                                <Card>
                                    <div className="imgbox">
                                        <img src={item.slider_images_list ? item.slider_images_list[0] : ''} alt={item.center_name} />
                                    </div>
                                    <CardContent>
                                        <div className="data">
                                            <h5>{item.center_name}</h5>
                                            <p>{item.address}</p>
                                            <div className="rating-wrapp">
                                                <StarRatings
                                                    rating={+props.rating || 0}
                                                    starRatedColor="#F46451"
									                starEmptyColor="#DFE7EC"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="21px"
                                                    starSpacing="0"
                                                />
                                                <span>{props.rating}</span>
                                                <span>({props.rating_count} Reviews)</span>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>
                    )
                }
                </Grid>
            </div>
        </section>
    );
});

export default OtherLocation;
