import React, { useState, useEffect } from 'react';
import StarRatings from 'react-star-ratings';
import {
	Grid,
	RadioGroup,
	Radio,
	FormControlLabel,
	TextareaAutosize
} from '@material-ui/core';

import Cart from './Cart';
import Loader from './Loader';
import server from '../helpers/config';


const BookingStep3 = React.memo(props => {
	const anyone = {
		id: 0,
		username: 'anyone',
		job_title: 'Maximum Availability'
	};
	const [employees, setEmployees] = useState([anyone]);
	useEffect(() => {
		setEmployees([
			anyone,
			...props.employees
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.employees.length]);

	const selectEmployee = id => {
		const selectedEmployee = employees.filter(i => i.id === id)[0] || {};
		props.handleSelectEmployee(selectedEmployee);
	}

	return (
		<Grid container spacing={8} className="popup-container">
			<Grid item xs={12} sm={12} md={7}>
				<div className="list-of-radio-wrapp">
					<h3>Booking Notes</h3>
					<TextareaAutosize className="booking-note" aria-label="empty textarea" placeholder="Please write down your color, or nails design" onChange={props.handleSetNote} defaultValue={props.note} rows={3}  cols={50}/>
					<h3>List of Technicians</h3>
					<RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
						{
							props.employees.length ? (
								<ul className="list-of-radio list-of-technicians">
									{
										employees.map(emp =>
											<li className="item" key={emp.id}>
												<div className="img-box">
													<img title={emp.username} alt={emp.username} src={emp.avatar_link ? `${server.ip}:${server.port}/${emp.avatar_link}` : `/images/user-img.png`} />
												</div>
												<FormControlLabel value={emp.id} control={<Radio onChange={() => selectEmployee(emp.id)} checked={props.cart.employee.id === emp.id} />} label={emp.username.toUpperCase()} />
												<div className="rating-wrapp">
													<StarRatings
														rating={+emp.average_review || 0}
														starRatedColor="#F46451"
														starEmptyColor="#DFE7EC"
														numberOfStars={5}
														name='rating'
														starDimension="25px"
														starSpacing="0"
													/>
												</div>
												<div className="job">{emp.job_title}</div>
											</li>
										)
									}
								</ul>
							) :
								props.loading ? <Loader /> :
									props?.center?.is_services_display==true?<div className="err">There is no availability on selected services. Please try other ones instead</div>:''
						}
					</RadioGroup>
				</div>
			</Grid>
			<Grid item xs={12} sm={12} md={5}  className="mrgn-top-30 hide-in-mob">
				<Cart
					center={props.center}
					cart={props.cart}
				></Cart>
			</Grid>
		</Grid>
	);
});

export default BookingStep3;
