import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import store from './store';
import SpaApp from './containers/SpaApp.js';
import Owner from './containers/Owner.js';
import Center from './containers/Center.js';
import NotFound from './components/NotFound';

const App = () => 
    <Provider store={store}>
        <Router>
            <SpaApp dispatch={store.dispatch} getState={store.getState}>
                <Switch>
                    <Route path="/owner/arrivalcheckin/:key" exact component={Owner} />
                    <Route path="/center/arrivalcheckin/:key" exact component={Center} />
                    <Route component={NotFound} />
                </Switch>
            </SpaApp>
        </Router>
    </Provider>;

export default App;
