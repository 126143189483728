import React from 'react';
import server from '../helpers/config';
import { default  as Carousel } from "react-slick";

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    className: 'home-slider',
    responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows:false,
            dots: true,
          }
        }  
	]
};
const Slider = React.memo(props => {
    return (
        <Carousel {...settings}>
            {
                props.slider_images_list.map((i, index) => <React.Fragment key={index}>
                    <div className="img-box"><img src={`${server.ip}:${server.port}/${i}`} alt={props.center_name} /></div>
                    {/* <div className="img-box"><img src={`/images/trash/${i}`} alt={props.center_name} /></div> */}
                </React.Fragment>)
            }
        </Carousel>
    ); 
});

export default Slider;