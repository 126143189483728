const initialState = {
    item: [],
    total: 0,
    employee: {},
    date: null,
    customer_id: null,
    customer_name: null,
    duration: 0,
    taxValue: 0,
    taxAmount: 0,
    customer: {},
    consentId: null
}

const cart = (state = initialState, { type = '', payload = {} }) => {
    const { is_tax_online = true } = payload || {};
    switch (type) {
        case 'ADD_TO_CART':
            const { item = {} } = payload;
            state = {
                ...state,
                total: 0,
                duration: 0,
                item: [...state.item, item],
                taxAmount: 0
            };
            state.item.forEach(i => {
                state.total += +i.price
                state.duration += +i.duration
            });
            if (state.taxValue > 0 && is_tax_online) {
                state.taxAmount = parseFloat((state.taxValue * state.total).toFixed(2));
                state.total = parseFloat((state.total + state.taxAmount).toFixed(2));
            }
        break;

        case 'REMOVE_FROM_CART':
            const { sid } = payload;
            state = {
                ...state,
                total: 0,
                duration: 0,
                item: state.item.filter(i => i.id !== sid),
                taxAmount: 0
            };
            state.item.forEach(i => {
                state.total += +i.price
                state.duration += +i.duration
            });
            if (state.taxValue > 0 && is_tax_online) {
                state.taxAmount = parseFloat((state.taxValue * state.total).toFixed(2));
                state.total = parseFloat((state.total + state.taxAmount).toFixed(2));
            }
        break;

        case 'SELECT_EMPLOYEE':
            state = {
                ...state,
                employee: payload
            };
        break;

        case 'SELECT_TIME':
            state = {
                ...state,
                date: payload
            };
        break;

        case 'BOOKING_SUCCESS':
            state = {
                ...state,
                customer_id: payload.customer_id,
                customer_name: payload.customer_name || null
            };
        break;

        case 'RE_INIT_BOOKING':
        case 'CLOSE_BOOKING':
            state = {
                ...initialState,
                taxValue: state.taxValue
            };
        break;

        case 'CHANGE_BOOKING':
            state = {
                ...state,
                item: payload.item || [],
                employee: payload.employee || {},
                date: payload.date || null,
                total: 0,
                duration: 0,
                taxAmount: 0
            };
            state.item.forEach(i => {
                state.total += +i.price
                state.duration += +i.duration
            });
            if (state.taxValue > 0) {
                state.taxAmount = parseFloat((state.taxValue * state.total).toFixed(2));
                state.total = parseFloat((state.total + state.taxAmount).toFixed(2));
            }
        break;

        case 'UPDATE_CUSTOMER':
            state = {
                ...state,
                customer: payload ? {...payload} : {},
            };
        break;

        case 'CENTER_SUCCESS':
            state = {
                ...state,
                taxValue: payload.tax || 0
            };
        break;

        case 'SET_CONSENT_ID':
            state = {
                ...state,
                consentId: payload || null
            };
        break;        

        default:
        break;
    }
    
    return state;
};

export default cart;