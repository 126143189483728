import requestService from '../services/requestService';

const getCenterByKey = query => {
  const request = {
    method: 'get',
    url: `/center/${query.ckey}`,
    params: {
      type: 'center_by_key',
    }
  };

  return requestService(request);
};

const getBusinessHour = query => {
  const request = {
    method: 'get',
    url: `/center/${query.cid}/business-hour`,
    params: {
      type: 'business_hour',
    }
  };

  return requestService(request);
};

export default {
    getCenterByKey,
    getBusinessHour
};
