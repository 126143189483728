import api from '../api';

const serviceLoading = () => ({
    type: 'SERVICE_LOADING'
});

const serviceSuccess = category => ({
    type: 'SERVICE_SUCCESS',
    payload: category
});

const serviceFail = () => ({
    type: 'SERVICE_FAIL'
});

const getServices = cid => {
    return dispatch => {
        dispatch(serviceLoading());
        api.get('/center/:cid/category/service', {
            params: { cid }
        }).then(res => {
            dispatch(serviceSuccess(res.data));
        }).catch(err => dispatch(serviceFail()));
    }
}

export {
    getServices
}