import api from '../api';

const bookingRequest = () => ({
    type: 'BOOKING_REQUEST'
});

const bookingSuccess = booking => ({
    type: 'BOOKING_SUCCESS',
    payload: booking,
});

const bookingFail = () => ({
    type: 'BOOKING_FAIL'
});

const createBooking = data => {
    return dispatch => {
        dispatch(bookingRequest());
        api.post('/booking', data).then(res => {
            dispatch(bookingSuccess({booking_id: res.data.booking_id, customer_id: data.customer_id, customer_name: data.customer_name}));
        }).catch(err => {
            dispatch(bookingFail());
        });
    }
}

const reInitBooking = () => ({
    type: 'RE_INIT_BOOKING'
});

const closeBooking = () => ({
    type: 'CLOSE_BOOKING'
});

const cancelBookingRequest = () => ({
    type: 'CANCEL_BOOKING_REQUEST'
});

const cancelBookingSuccess = () => ({
    type: 'CANCEL_BOOKING_SUCCESS'
});

const cancelBookingFail = payload => ({
    type: 'CANCEL_BOOKING_FAIL'
});

const cancelBooking = data => {
    return dispatch => {
        dispatch(cancelBookingRequest());
        api.post('/booking/:id/cancel', data).then(res => {
            dispatch(cancelBookingSuccess());
        }).catch(err => {
            dispatch(cancelBookingFail());
        });
    }
};

const updateBookingRequest = () => ({
    type: 'UPDATE_BOOKING_REQUEST'
});

const updateBookingSuccess = () => ({
    type: 'UPDATE_BOOKING_SUCCESS'
});

const updateBookingFail = payload => ({
    type: 'UPDATE_BOOKING_FAIL'
});

const updateBooking = payload => {
    return dispatch => {
        const { data, booking_id } = payload;
        dispatch(updateBookingRequest());
        api.post('/booking/:id', data, { params: { booking_id } }).then(() => {
            dispatch(updateBookingSuccess());
        }).catch(err => {
            dispatch(updateBookingFail());
        });
    }
};

export {
    createBooking,
    reInitBooking,
    closeBooking,
    cancelBooking,
    updateBooking
}