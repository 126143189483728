import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import api from '../api';

const getBusinessHour = cid => {
    return api.get('/center/:cid/business-hour', { params: { cid } });
}

const sortBusinessHour = bh => {
    const sortOrder = [2, 3, 4, 5, 6, 7, 1];
    const newBh = [];
    sortOrder.forEach(i => {
        newBh.push(bh.find(d => d.day_number === i));
    });
    return newBh;
};

const About = React.memo(props => {
    const [businessHour, setBusinessHour] = useState([]);

    useEffect(() => {
        if (props.id) {
            getBusinessHour(props.id).then(res => {
                const { data = [] } = res;
                setBusinessHour(sortBusinessHour(data));
            }).catch(err => {
                setBusinessHour([]);
            });
        }
    }, [props.id]);

    return (
        <section className="home-about">
            <div className="container">
                <h4>Let’s Get in Touch</h4>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={4} >
                        <div className="map">
                            <iframe title="map" src={`https://www.google.com/maps?output=embed&q=${props.address}`} allowFullScreen=""></iframe>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} >
                        <h5><i><img src="/images/time.svg" alt="" /></i>Business hours:</h5>
                        <table>
                            <tbody>
                                {
                                    businessHour.map(i => <tr key={i.day_number}>
                                        <th>{i.day_name} </th>
                                        <td>
                                            {i.is_on ? `${i.start_hour}:${('0' + i.start_minute).slice(-2)} ${i.start_period.toUpperCase()} - ${i.end_hour}:${('0' + i.end_minute).slice(-2)} ${i.end_period.toUpperCase()}` : 'Closed'}
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={6} xl={5}>
                        <div className="data">
                            <h5>About us</h5>
                            <p>
                                {props.about_us}
                            </p>
                        </div>
                    </Grid>

                </Grid>
            </div>
        </section>
    );
});

export default About;