const addToCart = payload => ({
    type: 'ADD_TO_CART',
    payload
});

const removeFromCart = payload => ({
    type: 'REMOVE_FROM_CART',
    payload
});

const selectDate = date => ({
    type: 'SELECT_TIME',
    payload: date
});

const changeBooking = payload => ({
    type: 'CHANGE_BOOKING',
    payload
});

const updateCustomer = payload => ({
    type: 'UPDATE_CUSTOMER',
    payload
});

const setConsentId = consentId => ({
    type: 'SET_CONSENT_ID',
    payload: consentId
});

export {
    addToCart,
    removeFromCart,
    selectDate,
    changeBooking,
    updateCustomer,
    setConsentId,
}