import React from 'react';
import { DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const BookingHeader = React.memo(props => {
	const steps = [
		{ step: 1, name: props.center.center_name },
		{ step: 2, name: 'Select Services', backText: 'Back to Appointment' },
		{ step: 3, name: 'Select a Technician', backText: 'Back to service' },
		{ step: 4, name: 'Select Time', backText: 'Back to technician' },
		{ step: 5, name: props.isManageBooking ? 'Self-Checkin' : 'Complete appointment', backText: 'Back to select time' },
		{ step: 6, name: 'Success' }
	];
	const { name, backText } = steps.filter(i => i.step === props.step)[0] || {};
    return (
		<DialogTitle id="alert-dialog-title">
			<span className="ttl-txt">{name}</span>
			{(props.step === 2 && props.consentId === null) && <a href="/#" onClick={e => {e.preventDefault();props.handleBack();}} className="btn-back">
					<svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M19.4973 5.99995L4.83083 5.99995L9.32162 1.63992C9.7592 1.21592 9.68751 0.584914 9.1634 0.231912C8.63805 -0.12209 7.8593 -0.0640907 7.42172 0.359912L1.24116 6.35995C1.19296 6.40695 1.16947 6.46195 1.13362 6.51395C1.10395 6.55595 1.06811 6.59195 1.04586 6.63795C0.990232 6.75295 0.956858 6.87395 0.956858 6.99595C0.956858 6.99695 0.955622 6.99895 0.955622 6.99996C0.955622 7.00096 0.956858 7.00295 0.956858 7.00396C0.956858 7.12596 0.990232 7.24696 1.04586 7.36196C1.06811 7.40796 1.10395 7.44396 1.13362 7.48596C1.16947 7.53796 1.19296 7.59296 1.24116 7.63996L7.42172 13.64C7.6677 13.877 8.01876 14 8.37229 14C8.65165 14 8.93225 13.924 9.1634 13.768C9.68751 13.415 9.7592 12.784 9.32162 12.36L4.83083 7.99996L19.4973 7.99996C20.1796 7.99996 20.7334 7.55196 20.7334 6.99996C20.7334 6.44795 20.1796 5.99995 19.4973 5.99995Z" fill="white" />
					</svg>
					<span>{backText}</span>
				</a>
			}
			{
				!! (backText && !props.isManageBooking && props.step !== 2) && <a href="/#" onClick={e => {e.preventDefault();props.handleBack();}} className="btn-back">
					<svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M19.4973 5.99995L4.83083 5.99995L9.32162 1.63992C9.7592 1.21592 9.68751 0.584914 9.1634 0.231912C8.63805 -0.12209 7.8593 -0.0640907 7.42172 0.359912L1.24116 6.35995C1.19296 6.40695 1.16947 6.46195 1.13362 6.51395C1.10395 6.55595 1.06811 6.59195 1.04586 6.63795C0.990232 6.75295 0.956858 6.87395 0.956858 6.99595C0.956858 6.99695 0.955622 6.99895 0.955622 6.99996C0.955622 7.00096 0.956858 7.00295 0.956858 7.00396C0.956858 7.12596 0.990232 7.24696 1.04586 7.36196C1.06811 7.40796 1.10395 7.44396 1.13362 7.48596C1.16947 7.53796 1.19296 7.59296 1.24116 7.63996L7.42172 13.64C7.6677 13.877 8.01876 14 8.37229 14C8.65165 14 8.93225 13.924 9.1634 13.768C9.68751 13.415 9.7592 12.784 9.32162 12.36L4.83083 7.99996L19.4973 7.99996C20.1796 7.99996 20.7334 7.55196 20.7334 6.99996C20.7334 6.44795 20.1796 5.99995 19.4973 5.99995Z" fill="white" />
					</svg>
					<span>{backText}</span>
				</a>
			}
			<ul className="bult-steps">
				<li><a href="/#" onClick={e => e.preventDefault()} className={props.step === 1 ? 'active' : ''}></a></li>
				<li><a href="/#" onClick={e => e.preventDefault()} className={props.step === 2 ? 'active' : ''}></a></li>
				<li><a href="/#" onClick={e => e.preventDefault()} className={props.step === 3 ? 'active' : ''}></a></li>
				<li><a href="/#" onClick={e => e.preventDefault()} className={props.step === 5 ? 'active' : ''}></a></li>
			</ul>
        </DialogTitle>
    ); 
});

export default BookingHeader;