import React from 'react';
import { Button, Grid } from '@material-ui/core';
import StarRatings from 'react-star-ratings';
import Slider from './Slider';

const SpaInfo = React.memo(props => {
    return (
        <section className="home-banner">
			<div className="container">
				<Grid container spacing={3}>
				<Grid item xs={12} sm={6} md={6} lg={7} className="home-banner-slidebox">
						{!! props.slider_images_list.length && <Slider
							center_name={props.center_name}
							slider_images_list={props.slider_images_list || []}
						></Slider>}
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={5} className="home-banner-data">
						<div className="data">
							<h1>{props.center_name}</h1>
							<h5>
								<div className="icon">
									<img src="/images/map-icon.svg" alt="" />
								</div>{props.address}
							</h5>
							<div className="rating-wrapp">
								<span>{props.rating}</span>
								<StarRatings
									rating={+props.rating || 0}
									starRatedColor="#F46451"
									starEmptyColor="#DFE7EC"
									numberOfStars={5}
									name='rating'
									starDimension="21px"
									starSpacing="0"
								/>
								<span>({props.rating_count} Reviews)</span>
							</div>
							{!! props.center_name && <Button className="btn-blue hide-in-mob" variant="contained" onClick={() => props.bookNow()}> Book an Appointment </Button>}
						</div>
					</Grid>
					
				</Grid>
        	</div>
      	</section>
    ); 
});

export default SpaInfo;