import  React, {useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import api from '../api';
import {
	Grid,	
	Checkbox,	
	TextField, Button
} from '@material-ui/core';
import {
    covidFormSucc
} from '../actions';

const CovidForm = (props) =>{
    let queArrValues = [
        {'isYes': null},{'isYes': null},{'isYes': null},{'isYes': null},
        {'isYes': null},{'isYes': null},{'isYes': null},{'isYes': null},
        {'isYes': null},{'isYes': null},{'isYes': null},
    ]
	let sigPad = {};

	const dt = new Date();	
	const currDate = `${dt.getFullYear()}-${('0' + (dt.getMonth() + 1)).slice(-2)}-${('0' + dt.getDate()).slice(-2)}`;

    const [protocol, setProtocol] = useState(true);
    const [queArr, setQueArr] = useState(queArrValues);
	const [covidFormDate, setCovidFormDate] = useState(currDate);
	const [isOpenSignPopup, setIsOpenSignPopup] = useState(false);
	const [trimmedDataURL, setTrimmedDataURL] = useState(null);
	const [signedUrlCode, setSignedUrlCode] = useState(null);
	const [isEnableSubmit, setIsEnableSubmit] = useState(0);
	const [isSignDraw, setIsSignDraw] = useState(false);

	React.useEffect(() => {
		// console.log("trimmedDataURL - covidFormDate", trimmedDataURL, covidFormDate)

		//check if all options are provided
		const isAnyNullVal = queArr.map((o) => o.isYes).indexOf(null);
		// console.log("isAnyNullVal", isAnyNullVal)

		//check if remaining points are checked
		const reqdPointsChkArr = [5,6,7,8,9,10];
		let isReqPointsChked = true;
		reqdPointsChkArr.map((v) => {
			if(queArr[v]['isYes'] === false){
				isReqPointsChked = false;
				return;
			}				
		})

		if(trimmedDataURL !== null && covidFormDate !== '' && isAnyNullVal == '-1' && isReqPointsChked){				
			setIsEnableSubmit(1)
		}else{
			setIsEnableSubmit(0)
		}						
	}, [covidFormDate, trimmedDataURL, queArr]);

    const onSetDone = () =>{
        setProtocol(true);
		props.moveBack();
    }

    const onSelectChoice = (queInd, opt) =>{
		const newQueryArr = queArr;
		if(opt === "yes" || opt === "no"){
        	newQueryArr[queInd]['isYes'] = (opt === 'yes')?true:false;
		} else{
			if(queArr[queInd]['isYes'] === null)
				newQueryArr[queInd]['isYes'] = true;
			else
				newQueryArr[queInd]['isYes'] = !queArr[queInd]['isYes'];
		}			
        setQueArr([...newQueryArr]);
    }

	const handleChangeDate = (e) => {
		setCovidFormDate(e.target.value);
	}

	const onShowSignPopup = () => {
		setIsOpenSignPopup(true);
		setIsSignDraw(false);
	}

	const clear = () => {
		sigPad.clear();
		setIsSignDraw(false);
	}

	const signDone = () => {
		const imgUrl = sigPad.getTrimmedCanvas().toDataURL('image/png');		
		urltoFile(imgUrl)
		.then(function(file){ 
			setSignedUrlCode(imgUrl);			
			setTrimmedDataURL(file);
			setIsOpenSignPopup(false);		
		});	
	}

	const urltoFile = (url) => {
		var filename = 'signature.png' 
		var mimeType = 'image/png'
		return (fetch(url)
			.then(function(res){return res.arrayBuffer();})
			.then(function(buf){return new File([buf], filename,{type:mimeType});})
		);
	  }

	const submitCovidForm = () => {
		//check if covid infected person
		const covidYesIndexArr = [0,1,2,3,4];
		const isCovidInfectedIndex = queArr.map((o) => o.isYes).indexOf(true);
		const isCovidYes = covidYesIndexArr.includes(isCovidInfectedIndex);

		if(isCovidYes){
			setProtocol(false);
			return;
		}

		setIsEnableSubmit(2);

		const formData = new FormData();
		formData.append('customer_name', props.customer.fullname);
		formData.append('phone', props.customer.tel);
		formData.append('birthday', '2010-10-10');
		formData.append('spa_center_id', props.centerDetails.id);
		formData.append('signature', trimmedDataURL);		
		formData.append('symptoms', (queArr[0]['isYes'])?1:0);
		formData.append('travelled', (queArr[1]['isYes'])?1:0);
		formData.append('close_contact', (queArr[2]['isYes'])?1:0);
		formData.append('investigated_covid', (queArr[3]['isYes'])?1:0);
		formData.append('positive_covid', (queArr[4]['isYes'])?1:0);
		formData.append('known_covid', (queArr[5]['isYes'])?1:0);
		formData.append('air_travel_covid', (queArr[6]['isYes'])?1:0);
		formData.append('might_contract_covid', (queArr[7]['isYes'])?1:0);
		formData.append('follow_salon_guide', (queArr[8]['isYes'])?1:0);
		formData.append('characteristics_covid', (queArr[9]['isYes'])?1:0);
		const d = new Date();
		const utc_offset = (d.getTimezoneOffset() * -1);
		formData.append('utc_offset', utc_offset);	
		
		api.post('/booking/covid/consent', formData).then(res => {
			console.log("RESPONSE COVID FORM",res)
			props.setCovidConsentId(res.data.data.id);
			// setIsEnableSubmit(1);
			props.setUnsetCovidVerfied(props.customer.tel, props.centerDetails.id);
			if(props?.centerDetails?.is_services_display==false){
				props.handleNext("skip",res.data.data.id)
			}
		}).catch((error) => {
			setIsEnableSubmit(1);
			console.log("ERROR COVID FORM", error);
		});
	}

	const beginDraw = () => {
		if(!isSignDraw)
			setIsSignDraw(true);
	}
	
    return (
        <div className="covid-consent-form">

		{!protocol && <div className="covid-consent-form-popup">
            <div className="covid-consent-form-popup-aside">
                <h5>Protocol for screening of clients for COVID-19 upon arrival </h5>
                <p>Please understand that if you answers YES to any of the questions, you SHOULD NOT be allowed on the business premise and we may have to ask you leave.</p>
                <a href="#" onClick={() => onSetDone()}>DONE</a>
            </div>
		</div>}

		{isOpenSignPopup && <div className="covid-consent-form-popup">
            <div className="covid-consent-sign-popup-aside">
				<SignatureCanvas penColor='black' ref={(ref) => { sigPad = ref}}
    				canvasProps={{width: 800, height: 400, className: 'sigCanvas'}} 
					onBegin={() => beginDraw()}
				/>
				<div className="canvasBtns">
					<Button className={`${!isSignDraw?'btn-blue mr_10':'bg_color_red mr_10'}`} variant="contained" onClick={() => clear()}>CLEAR</Button>
					<Button className="btn-blue" variant="contained" onClick={() => signDone()}>DONE</Button>
				</div>
			</div>
		</div>}

			 <h3 className="text-center mrgnBtm0">{props.centerDetails.center_name}</h3>
			 <span className="subTtl text-center">{props.centerDetails.address}</span>
			 <h5>COVID-19 Services Consent Form</h5>
			 <ul className="covid-consent-form__name">
				<li><label>NAME:</label> <span>{props.customer.fullname}</span></li> 
				<li><label>PHONE:</label> <span>{props.customer.tel}</span></li>  
			 </ul>
			 <ul className="covid-consent-form__list">
			 <h4>Screening</h4>
				<li>1. Do you have any of the following symptoms (Fever (greater than 38.0C), Cough, Shortness of Breath / Difficulty Breathing, Sore throat, Runny Nose)?
					<div className="chklist last">
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[0].isYes != null && queArr[0].isYes)} 
                                                        onChange={() => onSelectChoice(0, 'yes')}
                                                    /> <span>Yes</span> </div>
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[0].isYes != null && !queArr[0].isYes)}  
                                                        onChange={() => onSelectChoice(0, 'no')}
                                                    /> <span>No</span> </div>
					</div>
				</li>
				<li>2. Have you, or anyone in your house hold travelled outside of Canada in the last 14 days?
					<div className="chklist last">
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[1].isYes != null && queArr[1].isYes)} 
                                                        onChange={() => onSelectChoice(1, 'yes')}
                                                    /> <span>Yes</span> </div>
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[1].isYes != null && !queArr[1].isYes)} 
                                                        onChange={() => onSelectChoice(1, 'no')}
                                                    /> <span>No</span> </div>
					</div>
				</li>
				<li>3. Have you, or anyone in your household been in contact in the last 14 days with someone who is being investigated or confirmed to be a case of COVID-19?
					<div className="chklist last">
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[2].isYes != null && queArr[2].isYes)} 
                                                        onChange={() => onSelectChoice(2, 'yes')}
                                                    /> <span>Yes</span> </div>
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[2].isYes != null && !queArr[2].isYes)} 
                                                        onChange={() => onSelectChoice(2, 'no')}
                                                    /> <span>No</span> </div>
					</div>
				</li>
				<li>4. Are you currently being investigated as a suspect case of COVID-19?
					<div className="chklist last">
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[3].isYes != null && queArr[3].isYes)} 
                                                        onChange={() => onSelectChoice(3, 'yes')}
                                                    /> <span>Yes</span> </div>
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[3].isYes != null && !queArr[3].isYes)} 
                                                        onChange={() => onSelectChoice(3, 'no')}
                                                    /> <span>No</span> </div>
					</div>
				</li>
				<li>5. Have you tested positive for COVID-19 within the last 14 days?
					<div className="chklist last">
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[4].isYes != null && queArr[4].isYes)} 
                                                        onChange={() => onSelectChoice(4, 'yes')}
                                                    /> <span>Yes</span> </div>
						<div className="chklist-item"> <Checkbox color="primary" 
                                                        checked={(queArr[4].isYes != null && !queArr[4].isYes)} 
                                                        onChange={() => onSelectChoice(4, 'no')}
                                                    /> <span>No</span> </div>
					</div>
				</li>
				<li> <div className="chklist first">
						<div className="chklist-item"> 
							<Checkbox color="primary" 
                                                        checked={(queArr[5].isYes != null && queArr[5].isYes)?true:false} 
                                                        onChange={() => onSelectChoice(5, 'toggle')}
                                                    /> 
						</div> 
					</div> 6. By checking this box I confirm that I, <strong>{props.customer.fullname},</strong> understand the novel corona virus causes the disease known as COVID-19.  
				</li>
				<li> <div className="chklist first">
						<div className="chklist-item"> 
							<Checkbox color="primary" 
                                                        checked={(queArr[6].isYes != null && queArr[6].isYes)?true:false} 
                                                        onChange={() => onSelectChoice(6, 'toggle')}
                                                    /> 
						</div> 
					</div> 7. By checking this box I confirm that I understand that air travel is significant increases my risk of contracting and transmitting the COVID-19 virus. I know that the Canadian Government has asked individuals to maintain social distancing of at least 2 metres (6 feet) and it is not possible to maintain this distance and receive services at salon.  
				</li>
				<li> <div className="chklist first">
						<div className="chklist-item"> 
							<Checkbox color="primary" 
                                                        checked={(queArr[7].isYes != null && queArr[7].isYes)?true:false} 
                                                        onChange={() => onSelectChoice(7, 'toggle')}
                                                    /> 
						</div> 
					</div> 8. By checking this box I confirm that I understand that due to the frequency of visits of other clients, the characteristics of the novel coronavirus, and the characteristics of nail, pedicure, manicure, waxing, eyelash, massage, or haircut services, that I have an elevated risk of contracting the novel coronavirus simply by being in the salon.
				</li>
				<li> <div className="chklist first">
						<div className="chklist-item"> 
							<Checkbox color="primary" 
                                                        checked={(queArr[8].isYes != null && queArr[8].isYes)?true:false} 
                                                        onChange={() => onSelectChoice(8, 'toggle')}
                                                    /> 
						</div> 
					</div>    9. By checking this box I confirm that I understand that I will have to follow the salon’s strict guidelines. Required to wear a mask at all time.
				</li>
				<li> <div className="chklist first">
						<div className="chklist-item"> 
							<Checkbox color="primary" 
                                                        checked={(queArr[9].isYes != null && queArr[9].isYes)?true:false} 
                                                        onChange={() => onSelectChoice(9, 'toggle')}
                                                    /> 
						</div> 
					</div> 10. By checking this box I confirm that I understand the following risks due to the characteristics of the COVID-19 virus:

					<ul className="covid-consent-form__sublist">
						<li>a)	COVID-19 virus is easily spread from person to person.</li>
						<li>b)	COVID-19 virus has a long incubation period during which carriers of the virus may not show symptoms and still be highly contagious. It is impossible to determine who has it, therefore I will not hold the salon responsible if I were to contract COVID-19 infection around and during the time of services.</li>
						<li>c) COVID-19 virus may cause serious medical condition and could lead to death.</li>
					</ul>
				</li>
			 </ul>
			 <ul className="covid-consent-form__list">
			 	<h4>Signature of client</h4>
				<li> <div className="chklist first">
						<div className="chklist-item"> 
						<Checkbox color="primary" 
								  checked={(queArr[10].isYes != null && queArr[10].isYes)?true:false}
								  onChange={() => onSelectChoice(10, 'toggle')}
                                                    /> 
						</div>
					</div> By checking this box I confirm that I have read and understand the content of this form. I declare that the information I have provided on this form is true, correct and complete. I knowingly and willingly consent to have services completed during the COVID-19 pandemic. I am fully aware and accept all number of risks associated upon entering the salon property and obtaining services from {props.centerDetails.center_name} during the ongoing COVID-19 pandemic. 
				</li>
				<li>By signing this consent form and clicking the Submit button I am acknowledging that {props.centerDetails.center_name} and all parties associated cannot be held liable in any way if I am later diagnosed with COVID-19.</li>
			</ul>
			<div className="covid-consent-form__btm"> 
				<Grid container spacing={3}>
					<Grid item xs={6}>
						
						{trimmedDataURL
							? <img className="sigImage"
							src={signedUrlCode}  onClick={() => onShowSignPopup()} />
							: <TextField label="Tap here to sign" className="cursor_pointer" disabled onClick={() => onShowSignPopup()} /> }
						<span>Signature of client</span>
					</Grid>
					<Grid item xs={6}> 
						<TextField disabled type="date" variant="outlined" name="covidFormDate" value={covidFormDate || ''} placeholder="Date" onChange={(e) => handleChangeDate(e)} />
						<span>Date</span>
					</Grid>
					<Grid item xs={12}> 
					<div className="btn-box">
						{(isEnableSubmit === 0) && <button className="btn-blue btn-main" disabled={!(queArr[10].isYes != null && queArr[10].isYes)?true:false}> Submit </button>}
						{(isEnableSubmit === 1) && <button className="btn-blue btn-main" onClick={() => submitCovidForm()} > Submit </button>}
						{(isEnableSubmit === 2) && <button className="btn-blue btn-main" > Submitting... </button>}
					</div>
					</Grid>
				</Grid>
			</div>
		</div>
    )
}

export default CovidForm;