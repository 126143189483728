import api from '../api';

const employeeLoading = () => ({
    type: 'EMPLOYEE_LOADING'
});

const employeeSuccess = center => ({
    type: 'EMPLOYEE_SUCCESS',
    payload: center,
});

const employeeFail = () => ({
    type: 'EMPLOYEE_FAIL'
});

const getEmployees = (serviceArr, cid, utc_offset) => {
    return dispatch => {
        dispatch(employeeLoading());
        api.get('/center/:cid/employee', {
            params: { cid: cid, service: serviceArr, utc_offset: utc_offset }
        }).then(res => {
            dispatch(employeeSuccess(res.data));
        }).catch(err => {
            dispatch(employeeFail());
        });
    }
}

const selectEmployee = employee => ({
    type: 'SELECT_EMPLOYEE',
    payload: employee
});

export {
    getEmployees,
    selectEmployee
}