import React from 'react';
import { AppBar, Toolbar, Button } from '@material-ui/core';

const Header = React.memo(props => {
	return (
		<AppBar position="static">
			<Toolbar>
				<div className="container">
					<a href={props.website_link || ''} target="_blank" rel="noopener noreferrer" className="logo" ><img src="/images/logo.png" alt="" /></a>
					<div className="head-right">
						<a href="/#" onClick={e => e.preventDefault()} className="call"><img src="/images/phone.png" alt="" />{props.tel}</a>
						<Button variant="contained" onClick={e => { e.preventDefault(); props.manageBooking(true) }}>Find my Appointment</Button>
					</div>
				</div> 
			</Toolbar>
		</AppBar>
	);
});

export default Header;